import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AclListComponent } from './acl/acl-list.component';
import { AclManageComponent } from './acl/acl-manage.component';
import { ImporterComponent } from './importer/importer.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploadComponent } from './files/file-upload/file-upload.component';
import { UploadsArchiveComponent } from './files/uploads-archive/uploads-archive.component';
import { FilesComponent } from './files/files.component';
import { ParamsListComponent } from './params/params-list.component';
import { ParamsManageComponent } from './params/params-manage.component';
import { KeysListComponent } from './keys/keys-list.component';
import { KeysManageComponent } from './keys/keys-manage.component';
import { VoucherChannelsListComponent } from './voucher-channels/voucher-channels-list.component';
import { ExternalListComponent } from './external/external-list.component';
import { ExternalManageComponent } from './external/external-manage.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    FileUploadModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    AclListComponent,
    AclManageComponent,
    ImporterComponent,
    FileUploadComponent,
    UploadsArchiveComponent,
    FilesComponent,
    ParamsListComponent,
    ParamsManageComponent,
    KeysListComponent,
    KeysManageComponent,
    VoucherChannelsListComponent,
    ExternalListComponent,
    ExternalManageComponent
  ]
})
export class UtilityModule {}
