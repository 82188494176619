import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyConfig, ConfigKey } from '../../../interfaces/configuration.interface';
import { ListService } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
import { UtilsService } from '../../../services/utils.service';
import swal2 from 'sweetalert2';

@Component({
    selector: 'app-params-manage',
    templateUrl: './params-manage.component.html',
    styleUrls: ['../../../app.component.css']
})
export class ParamsManageComponent implements OnInit, OnDestroy {

    isSoluzione1: boolean;

    // companies info
    urlCompanyPath = '/company/companies';
    companiesList: any[] = [];

    // testo in HTML per tipo pagina
    kindTitle: string;
    kindManage: number;

    keysList: ConfigKey[] = [];
    keysPath = '/configuration'
    dataConfiguration: CompanyConfig;

    configurationPath = '/configuration/detail'

    configurationForm = new FormGroup({
        name: new FormControl('', Validators.required),
        value: new FormControl('', Validators.required),
        idCompany: new FormControl('', Validators.required)
    });

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private listService: ListService,
        private utils: UtilsService,
        private logger: LoggingService
    ) {
        // Se creo nuova chiave
        if (this.route.snapshot.toString().indexOf('new') !== -1) {
            this.kindTitle = 'Assegnazione';
            this.kindManage = 1;
        } else { // se modifico chiave
            this.kindTitle = 'Modifica';
            this.kindManage = 0;
            if (localStorage.getItem('dataPassed') === '') {
                this.router.navigate(['/utility/params']);
                return;
            }
            this.dataConfiguration = JSON.parse(localStorage.getItem('dataPassed'));
            this.setFormValues();
            localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
        }
    }

    ngOnInit(): void {
        this.listService.listRouting = '/utility/params';
        this.isSoluzione1 = this.utils.isSol1(this.utils.getTypeUser());
        if (this.isSoluzione1) {
            this.getCompanies();
        } else {
            this.configurationForm.get('idCompany').setValue(localStorage.getItem('idCompany'));
        }
        this.getKeys();
    }

    ngOnDestroy(): void {
        this.listService.backToList = false;
    }

    back() {
        this.router.navigate(['/utility/params']);
    }

    onSubmit() {
        swal2.fire({
            title: 'Operazione in corso...',
            didOpen: function() {
              swal2.showLoading();
            }
        });
        const config: CompanyConfig = this.configurationForm.value;
        this.listService[this.kindManage ? 'newObject' : 'edit'](config, this.configurationPath).subscribe({
            next: _ => {
                swal2.fire('Successo', `${this.kindManage ? 'Assegnazione' : 'Modifica'} parametro avvenuta con successo`, 'success');
                this.back();
            },
            error: (error) => {
                this.logger.log('Error', error, 200);
                swal2.fire(
                    'Errore',
                    `Spiacente, non è stato possibile ${this.kindManage ? 'assegnare' : 'modificare'} il parametro`,
                    'error'
                );
            }
        });
    }


    // ---------- PRIVATE METHODS ---------- //

    private getCompanies() {
        this.listService.getListSelect(this.urlCompanyPath).subscribe((response) => {
            this.companiesList = response.results;
            this.logger.log('Lista aziende', this.companiesList, 300);
        }, (error) => {
            this.logger.log('Error', error, 200);
        });
    }

    private getKeys() {
        this.listService.getListSelect(this.keysPath).subscribe((response) => {
            this.keysList = response.results;
            this.logger.log('Lista chiavi', this.keysList, 300);
        }, (error) => {
            this.logger.log('Error', error, 200);
        });
    }

    /** Method that sets default values in form on key edit */
    private setFormValues(): void {
        this.configurationForm.get('name').setValue(this.dataConfiguration.name);
        this.configurationForm.get('value').setValue(this.dataConfiguration.value);
        this.configurationForm.get('idCompany').setValue(this.dataConfiguration.idCompany);
    }

}
