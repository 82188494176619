<div class="row" style="padding-top: 20px"></div>

<div class="">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <!-- TITLE -->
      <div class="ibox-title">
        <h2 class="col-xs-11">
          <strong>Chiavi esterne</strong>
        </h2>
        <div class="ibox-tools col-xs-1 pull-right">
          <button type="button" (click)="newExternal()" class="btn btn-view btn-outline">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <!-- CONTENT -->
      <div class="ibox-content">
        <!-- FILTERS -->
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <form #formExternalKeys="ngForm">
                <div class='col-sm-6 col-md-4 col-lg-3'>
                  <div class="form-group">
                    <label>Azienda di trasporto</label>
                    <div class='input-group col-xs-12'>
                      <select (change)="filterSearch()" type="text" id="idCompany" class="form-control" ngModel name="idCompany" idCompany #idCompany="ngModel">
                        <option [ngValue]="'all'">Tutte</option>
                        <option *ngFor="let r of companiesList" [ngValue]="r.idCompany">{{r.legalBusinessName}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class='col-sm-6 col-md-4 col-lg-3'>
                  <div class="form-group">
                    <label>Codice</label>
                    <div class='input-group col-xs-12'>
                      <input type="text" id="code" name="code" class="form-control" ngModel code #code="ngModel">
                    </div>
                  </div>
                </div>
                <div class='col-sm-6 col-md-4 col-lg-3'>
                  <div class="form-group">
                    <label>Stato</label>
                    <select (change)="filterSearch()" type="text" id="status" class="form-control" ngModel name="status" status #status="ngModel">
                      <option [ngValue]="true">Attiva</option>
                      <option [ngValue]="false">Non attiva</option>
                    </select>
                  </div>
                </div>
                <div class='col-xs-12 col-sm-6 col-sm-4 col-md-3 col-lg-2'>
                  <div class="form-group">
                    <label></label>
                    <div class='input-group col-md-12'>
                      <button type="button" (click)="filterSearch()" class="btn btn-view btn-outline">Cerca</button>
                      <button *ngIf="listService.visibleFilter" type="button" (click)="filterReset()" class="btn btn-view  btn-outline resetFilter">
                        <i class="fa fa-rotate-left"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- TABLE -->
        <p *ngIf="errorMessage && errorMessage != ''" class="bg-danger p-sm">
          <i class="fa fa-lg fa-frown-o" aria-hidden="true"></i>
          {{ errorMessage }}
        </p>
        <div *ngIf="!errorMessage" class="table-responsive">
          <table class="table table-striped table-bordered table-hover dataTables-example">
            <thead>
              <tr>
                <th>Azienda di trasporto</th>
                <th>Nome</th>
                <th>Codice</th>
                <th>Chiave API</th>
                <th class="text-center">Attiva</th>
                <th class="text-center">Azioni</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of dataList" style="cursor:pointer">
                <td class="col-md-2">{{ getConfigurationCompany(data.idCompany) }}</td>
                <td>{{data.name || '-'}}</td>
                <td>{{data.code || '-'}}</td>
                <td>{{data.apiKey || '-'}}</td>
                <td class="text-center"><i class="fa fa-lg" [ngClass]="data.enabled ? 'fa-check' : 'fa-times'"></i></td>
                <td class="text-center">
                  <button type="button" class="btn btn-success btn-outline" (click)="selectRow(data)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button *ngIf="data.enabled" type="button" class="btn btn-danger btn-outline float-right" style="margin-left: 4px;"
                    (click)="deleteExternal(data.id)">
                    <i class="fa fa-toggle-off"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ngb-pagination *ngIf="listService.totalRows > listService.rows" class="pull-right" [collectionSize]="listService.totalRows"
            [maxSize]="10" [pageSize]="listService.rows" [(page)]="listService.page" (pageChange)="pageChange($event)">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>