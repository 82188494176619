<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h2>{{kindTitle}} chiave esterna</h2>
        </div>
        <form (ngSubmit)="onSubmit()" [formGroup]="externalForm">
          <div class="ibox-content">
            <div class="row" style="padding: 0 15px">
              <div class="form-group form-group col-xs-12 col-sm-6">
                <label for="idCompany">Azienda di trasporto</label>
                <select
                  type="text"
                  id="idCompany"
                  class="form-control"
                  formControlName="idCompany"
                  [disabled]="!kindManage">
                  <option *ngFor="let r of companiesList" [ngValue]="r.idCompany">{{r.legalBusinessName}}</option>
                </select>
              </div>
              <div class="form-group form-group col-xs-12 col-sm-6">
                <label for="enabled">Abilitato</label>
                <div style="display: flex; align-items: center;">
                  <input
                    type="checkbox"
                    id="enabled"
                    class="form-control"
                    formControlName="enabled"
                    style="width: 1.5rem; margin: 0; margin-right: 6px;"
                    [disabled]="!kindManage" />
                  <div>Attivare la chiave esterna?</div>
                </div>
              </div>
            </div>
            <!-- External values -->
            <div class="form-group form-group col-xs-12 col-sm-6">
              <label for="name">Nome</label>
              <input
                type="text"
                id="name"
                class="form-control"
                formControlName="name" />
            </div>
            <div class="form-group form-group col-xs-12 col-sm-6">
              <label for="code">Codice</label>
              <input
                type="text"
                id="code"
                class="form-control"
                formControlName="code"
                [disabled]="!kindManage" />
            </div>
            <div class="form-group form-group col-xs-12 col-sm-12">
              <label for="apiKey">Chiave API</label>
              <input
                type="text"
                id="apiKey"
                class="form-control"
                formControlName="apiKey" />
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">
            <button class="btn btn-default" type="button" (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!externalForm.valid">Salva</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>